@import 'https://js.arcgis.com/4.20/@arcgis/core/assets/esri/themes/light/main.css';
@import 'theme.scss';
@import 'status.scss';
@import '~quill/dist/quill.snow.css';


.padding{
    padding:10px;
}
.title-text{
    font-size: 32px !important;
    font-weight: 300 !important;
    color: var(--accent-darker-color);
    margin: 0px !important;
}
.back{
    border-radius: 10px !important;
    background-color: var(--text-primary-color) !important;
    color:var(--accent-darker-color) !important;
    margin-right:10px !important;
    box-shadow: none !important;
}
.dark-input{
    &.without-error{
        .mat-form-field-wrapper{
            padding-bottom: 0px;
        }
    }
    .mat-form-field-outline-thick{
        opacity: 1 !important;
    }
    .mat-form-field-label{
        color: var(--primary-color);
    }
    
.mat-form-field-outline-start ,.mat-form-field-outline-gap,.mat-form-field-outline-end{
    border-width: 1px !important;
}
    .mat-form-field-required-marker{
        color:var(--warn-darker-color);
    }
&.button-left{
.mat-form-field-outline-end {
        border-radius: 0px;
    }
}
&.custom-time-picker{
  .mat-form-field-infix{
    padding: 0.6em 0 0.6em 0;
  }
  input[type='time']{
    font-size: 16px;
  }
  input[type='time']::-webkit-calendar-picker-indicator{
    font-size: 22px;
    opacity: 0.5;
  }
}
}
.light-input{
  &.without-error{
      .mat-form-field-wrapper{
          padding-bottom: 0px;
      }
  }
  .mat-form-field-outline-thick{
      opacity: 1 !important;
  }
  .mat-input-element{
    color:#fff;
    caret-color: #fff;
  }
  .mat-form-field-label,.mat-select-arrow,.mat-select-value{
      color: var(--primary-lighter-color);
  }
  &.mat-focused{
    .mat-form-field-label,&.mat-primary .mat-select-arrow,.mat-select-value{
      color: var(--primary-lighter-color);
  }
  }
.mat-form-field-outline-start ,.mat-form-field-outline-gap,.mat-form-field-outline-end{
  border-width: 1px !important;
  color: var(--primary-lighter-color);
}
  .mat-form-field-required-marker{
      color:var(--warn-darker-color);
  }
&.button-left{
.mat-form-field-outline-end {
      border-radius: 0px;
  }
}
&.custom-time-picker{
  .mat-form-field-infix{
    padding: 0.6em 0 0.6em 0;
  }
  input[type='time']{
    font-size: 16px;
  }
  input[type='time']::-webkit-calendar-picker-indicator{
    font-size: 22px;
    filter:invert(1);

  }
}
}
.readonly-inputs{
    .mat-form-field-flex
    {
        background-color: var(--primary-lighter-color) !important;
        border-radius: 4px !important;
    }
    .mat-input-element:disabled {
        color:var(--primary-color);
    }
}
.custom-check{
    padding: 0px 10px;
    border-radius: 10px;
    .mat-checkbox-frame {
      border: 1px solid var(--primary-color);
    }
    .mat-checkbox-inner-container {
      width: 20px;
      height: 20px;
    }
  }
.white-check{
  .mat-checkbox-frame {
    border: 1px solid white !important;
  }
}
  .expansion-panel {
    background-color: var(--primary-lighter-color);
    .mat-content.mat-content-hide-toggle{
      margin-right: 0px;
    }
     .mat-expansion-panel-body {
      background-color: var(--text-accent-color);
      padding: 0;
      .mat-list-base .mat-list-item{
          height: 42px;
          .mat-list-item-content{
            justify-content: space-between;
          }
        }
    }
  }
  .work-at-glance-expand {
     .mat-expansion-panel-body {
      padding: 0;
    }
  }

   /*********** overlay color ***************/
  .cdk-overlay-dark-backdrop {
      background-color: rgba(0,0,0,0.8);
  }
  .light-overlay{
    background-color: rgba(0,0,0,0.3);
  }
  /*********** overlay color ***************/
  /*********** Tost messages ***************/
    $default-color:var(--primary-color);
    $info-color: var(--accent-darker-color);
    $success-color: var(--success-color);
    $warn-color: var(--warn-color);
    $error-color: var(--warn-darker-color);
    .default-toast-overlay,
    .info-toast-overlay,
    .success-toast-overlay,
    .warning-toast-overlay,
    .error-toast-overlay {
      border-left: 4px solid;
      background-color: var(--text-primary-color);
      color:var(--primary-color);
      &::before {
        font-family: "Material Icons";
        float: left;
        font-size: 24px;
        white-space: pre;
      }
    }
    .default-toast-overlay {
      border-left-color: $default-color;
      &::before {
        color: var(--text-primary-color);
      }
    }
    .info-toast-overlay {
      border-left-color: $info-color;
      &::before {
        content: "\e88e  ";
        color: $info-color;
      }
    }
    .success-toast-overlay {
      border-left-color: $success-color;
      &::before {
        content: "\e86c  ";
        color: $success-color;
      }
    }
    .warning-toast-overlay {
      border-left-color: $warn-color;
      &::before {
        content: "\e002  ";
        color: $warn-color;
      }
    }
    .error-toast-overlay {
      border-left-color: $error-color;
      &::before {
        content: "\e000  ";
        color: $error-color;
      }
    }
  
  /*********** Tost messages ***************/
  .loading-text
  {
      color: var(--text-primary-color);
      margin: 16px 0px !important;
      font-size: 32px !important;
  }

   /*********** Search ***************/
   .search{
    background: var(--primary-lighter-color);;
    border-radius: 5px;
    display: flex;
    margin: 5px 0px;
    padding: 10px;
    ::placeholder {
      color: var(--dark-bg-lighter-10);
      font-size: 14px;
    }
 }
   /*********** Employee List Dropdown ***************/
   .select-list-wrapper{
     padding: 10px;
     max-height: 295px !important;
    .custom-mat-option{ 
      flex-direction: row-reverse;
      height: 4em !important;
      border-bottom: 1px solid var(--primary-darker-color);
      margin: 5px 0px;
     
      &.mat-selected{
        background-color: var(--accent-darker-color);
        border-radius:5px;
        .employee-name{
          color: var(--text-primary-color)
        }
      };
    }
      .employee-detail{
          display: flex;
          align-items:center;
        img{
          border-radius:50%; 
          width:39px;
          height: 39px;
        }
        .employee-name{
          margin:0px 10px;
        }
      }
   }
   /*********** checkbox styles ***************/
   .large-checkbox{
    .mat-pseudo-checkbox {
      height: 20px !important;
      width: 20px !important;
    }
    .mat-pseudo-checkbox-checked {
      background-color: var(--primary-accent-color);
      &::after {
        width: 14px;
        height: 4px;
      }
    }
   }
   /*********** radio button styles ***************/
   .dark-radio-buttons.mat-radio-button {
    .mat-radio-outer-circle{
     border-color: var(--accent-darker-color) !important;
   }
   .mat-radio-inner-circle{
    background-color: var(--accent-darker-color);
   }
  }
  /*********** menu  ***************/
  .dark-menu{
    background-color: var(--primary-color);
  }

  .full-length-menu{
    max-width:100% !important;
  }
  /***********Select Message ***************/
  .non-selected{            
    height:  calc(100vh - 124px);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
    opacity: 0.5;
    .message{
        font-size: 36px;
        line-height: 40px;
        line-height: 40px;
        font-weight: 200;
        width:70%;
        text-align:left;
    }
  }
  /******** Expansion Panel modal *****/
  .right-panel{
    .expansion-panel {
      width:440px !important;
    }
  }

  /****** mat progress bar colors for capacity calculator  *******/

    .progressGreen .mat-progress-bar-fill::after {
      background-color: var(--success-color) !important;//green
  
}
  
  .progressOrange .mat-progress-bar-fill::after {
    background-color: var(--info-color) !important;//yellow
  }
  
  .progressRed .mat-progress-bar-fill::after {
    background-color: var(--warn-darker-color) !important;//red
  }
  

  .progressCapacity .mat-progress-bar-buffer{
    background-color:var( --primary-darker-color)  !important;
    }
    /****** mat-timepicker  *******/
.custom-time-picker{
  .ngx-mat-timepicker{
    height: auto;
    .ngx-mat-timepicker-control{
      // width:48px;
      min-width:48px;
      input{
      text-align: unset !important;
      padding-left: 5px;
      }
    }
  .mat-form-field-wrapper{
    padding: 0px;
    background-color: var(--text-primary-color);
    border:1px solid var(--primary-color);
    border-radius: 4px;
    font-size: 16px;
    height: 42px;
    display: flex;
    align-items: center;
    padding: 0px 2px;
   
  }
  
  .mat-form-field-infix{
    border-top: 0px;
  }
  .mat-form-field-underline {
    display: none;
    bottom:0px
  }
  .mat-select {
    line-height: 19px;
  }

}
&.light-picker .ngx-mat-timepicker {
  .separator-colon {
    color:#fff;
  }
.mat-form-field-wrapper{
    border:1px solid #fff;
   }
  }
}

.custom-block-picker{
  .ngx-mat-timepicker{
  .mat-form-field-wrapper{
    height: 34px !important;
  }}
  }
 /****** Audit Trial  *******/
 .audit-container .mat-dialog-container {
  padding: 0px;
  overflow-x: hidden;
}
.order-details .mat-figure{
  align-items: flex-start !important;
}

.diff {
  display: inline-block;
}
del.diff-key {
  border: 1px solid #f8a4a4;
}
del.diff {
  text-decoration: none;
  color: #b30000;
  background: #fadad7;
  span {
    color: #ee8177;
  }
}
.diff-level {
  margin-left: 1.6em;
  text-transform: capitalize;
}
.diff-holder {
  color: #666;
  margin: 0;
  span {
    color: #aaa;
  }
}
ins.diff {
  background: #eaf2c2;
  color: #406619;
  text-decoration: none;
  span {
    color: #aabf40;
  }
}
del.diff-key {
  border: 1px solid #f8a4a4;
}
ins.diff-key {
  border: 1px solid #a3ce4c;
  margin-top: -1px;
  position: relative;
}
/** workorder popup **/
.workorder-container .mat-dialog-container {
  padding: 10px;
  background-color: var(--primary-color);
}
mat-list-option[aria-selected="true"] {
  .mat-list-item-content{
      background: var(--accent-color);
      color: white;
      border-radius: 5px;
  }
}
.filtersTabs{
  .mat-list-item-content{
    margin: 5px;
  }
}
.orders_table_container{
  .mat-sort-header-arrow {
    color: var(--warn-color) !important;
  }
  .mat-sort-header-container {
    justify-content: center;
  }
}
.modal_table{
  .mat-sort-header-arrow {
    color: var(--warn-color) !important;
  }
  .mat-sort-header-container {
    justify-content: center;
  }
}
.mat-menu-hold{
  .mat-menu-item{
    height:35px;
    line-height:0px;
    color:white;
    .mat-icon{
      color: var(--primary-lighter-color);
      margin-right: 5px !important;
    }
    &.hold{
      border-top: 1px solid var(--primary-darker-color);
      .mat-icon{
        color: var(--info-color);
        margin-right: 5px !important;
      }
    }
  }
  
}

  .mat-menu-panel{
    min-height: 50px !important;
  }
  .balance{
    padding: 5px;
    .mat-menu-content {
        .balance-radio-button{
          &:not(:last-child){ padding: 8px 15px}
          &:last-child{ padding: 8px 15px 0px}
          font-size: 15px;
          .mat-radio-inner-circle{
            background-color: #ff5722;
          }
          .mat-radio-outer-circle{
            border-color: #ff5722;
          }
        }
      // }
    }
  }
  .selected-list{
    .mat-form-field-wrapper{
      width: 250px;
    }
  }
  .reason{
    .mat-form-field-wrapper{
      width: 250px;
    }
  }
  .mat-option-block{
      border-bottom: 1px solid var(--primary-darker-color);
      margin: 5px 0px;
    .mat-checkbox-layout{
      width: 100%;
      .mat-checkbox-inner-container {
          width:20px;
          height: 20px;
          margin-left: auto;
          margin-right: 7px;
        }
  }
  &.mat-checkbox-checked{
    .mat-checkbox-background {
      background-color: var(--accent-darker-color);
  }
  }
  }
  .accent-toggle{
    .mat-button-toggle-focus-overlay{
      display: none;
    }
    .mat-button-toggle {
      background-color: var(--primary-lighter-color);
      width:100px;
      font-weight: 500;
    }
    // &.mat-button-toggle-group-appearance-standard ,&.mat-button-toggle-group-appearance-standard .mat-button-toggle{
    //   border-color:  var(--primary-color);
    // }
  .mat-button-toggle-checked {
    background-color: var(--accent-color);
    color:#fff ;
  }}
  .CompanyworkCentersComponent{
    .mat-dialog-container{
      background-color: var(--primary-lighter-color);;
    }
  }
  .multi-select-md{
   .custom-mat-option{ 
     height: 3.5em !important;
     &.mat-selected{
       background-color: var(--accent-darker-color);
       border-radius:5px;
       margin:2px;
       .mat-option-text{
        color: white;
       }
     };
   }
  }
  .date-range{
    .mat-form-field-outline ,.mat-form-field-label{
        color: var(--primary-color) !important;
    }
}
.tooltip{
  font-size: 14px;
  background-color: var(--primary-color);
  &.tooltip-content{
    &::before{
       display: block;
       opacity: 0.5;
       font-weight: 500;
    }
  }
  &.hold-tooltip{
  &::before{
    content: "Hold Reason";
    }
}
&.holidaySupressed-tooltip{
  &::before{
    content: "Notice";
    }
 }
}
.editor-toolbar{
  border-color: var(--primary-color) !important;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  &.disabled{
    //border-color: var(--primary-darker-color) !important;
    opacity: 0.3;
    pointer-events: none;
  }
}

.dark-editor{
  border-color: var(--primary-color) !important;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  height:300px;
  p,h1,h2,h3,h4,h5,h6{
    margin-bottom: 0px;
  }
  
  &.ql-disabled{
    border-color: var(--primary-darker-color) !important;
    .ql-editor{
      color: var(--primary-darker-color) !important;
    }
    
  }
}
.appointment-container .mat-dialog-container {
  
  background-color: var(--primary-lighter-color);

}

.validator-form-field .mat-form-field-underline {
  display: none;
}

.expansion-panel-validator .validator-expansion-header{
  padding: 0px;
}

.expansion-panel-validator .mat-expansion-panel-content .mat-expansion-panel-body{
  padding: 0px;
}

.validator-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix{
  padding-top: 0px !important; 
  padding-right: 0px !important;
  padding-bottom: 0px !important; 
  padding-left: 8px !important;
  border-top: 4px solid transparent !important;
  border-left: 5px solid transparent;
}

.mat-select-validator .mat-select-trigger .mat-select-arrow-wrapper{
  border-top: 13px solid transparent !important;
}

.manufacturer-selected .mat-form-field-wrapper .mat-form-field-flex .mat-select-validator{
  border-top: 7px solid transparent !important;
}

.manufacturer-selected .mat-form-field-wrapper .mat-form-field-flex .mat-select-validator .mat-select-trigger .mat-select-arrow-wrapper{
  border-top: 0px solid transparent !important;
}

app-orders-auditlog mat-grid-list div .order-details .mat-grid-tile-content{
  align-items: start;
}