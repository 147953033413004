/** priority *************/
.priority-1 {
    background-color:var(--warn-light-color);
    color: var(--warn-darker-color)
}

.priority-2 {
    background-color:var(--info-lighter-color);
    color: var(--brown-color);
}

.priority-3 {
    background-color: var(--success-lighter-color);
    color: var(--success-color)
}

.priority-4,.priority-5,.priority-6,.priority-7,.priority-8{
    background-color: var(--brown-color);
    color:#fff;
}
/** priority *************/
/** Status *************/
.COMP{
    background-color: var(--accent-darker-color);
    color: #fff;
}
.INCM{
    background-color: #9956ED;
    color: #fff;
}
.AKNW{
    background-color: #670101;
    color: #fff
}
.HOLD{
    background-color: #FF971D;
    color: var(--primary-color);
}
.ENRT{
    background-color: var(--success-color);
    color: #fff;
}
.ONST{
    background-color: var(--accent-focus);
    color: #fff;
}
.CANC{
    background-color: #D3B38C;
    color:var(--primary-color);
}
.ASGN{
    background-color:#FCD58A;
    color:var(--primary-color);
}
.PRDS{
    background-color: var(--primary-lighter-color);
    color:var(--primary-purple);
    
    border: 1px dashed var(--primary-purple);
    
}
.DISP{
    background-color: var(--warn-light-color);
    color:var(--primary-color);
}
.UNSC{
    background-color: var(--warn-lighter-color);
    color:var(--primary-color);
}
.DECL{
    background-color: #EC4ABF;
    color:#fff
}
.PECA{
    background-color: #D3B38C;
    color:var(--primary-color);
}
.TECO{
    background-color:#243E66;
    color:#fff;
}
.ACPT{
    background-color:#657B97;
    color:#fff;
}
.blocked{
    color:var(--primary-color);
    background: repeating-linear-gradient(
        45deg,
        var(--warn-lighter-color),
        var(--warn-lighter-color) 10px,
        #999 10px,
        #999 11px
      );
    border: 1px solid var(--primary-color);
}
/** Status *************/